
const Global = {
  init: function () {
    this.cacheSelectors();
  },
  cacheSelectors: function () {
    this.lenis = null;
    this.Events.handleScroll;
    this.header = document.querySelector(".header");
    this.close = this.header.querySelector(".close");
    this.hamburguer = this.header.querySelector(".hamburguer");
    this.dropdown = document.querySelectorAll(".dropdown") || null;
    this.archor = document.querySelectorAll(".archor") || null;
    this.Events.verifySectionUrl();

    const formHome = document.querySelector(".form-home") || null;
    const formWork = document.querySelector(".form-work") || null;

    

    if(formHome) {
      this.Forms.validFormHome();
    }

    if(formWork) {
      this.Forms.validFormWork();
    }

    if (this.popupWork) {
      this.popupWork.forEach((element) => {
        element.addEventListener("click", (e) => {
          e.preventDefault();
          this.Events.openWorkModal;
        });
      });
    }

    if (this.archor) {
      this.Events.archorLinks(this.archor);
    }

    this.dropdown.forEach((el) => {
      el.addEventListener("click", () => {
        el.classList.toggle("active");
      });
    });

    this.hamburguer.addEventListener("click", () => {
      this.Events.toggleMenu();
    });

    this.close.addEventListener("click", () => {
      this.Events.toggleMenu();
    });

    window.addEventListener(
      "scroll",
      this.Helpers.debounce(this.Events.handleScroll, 50)
    );

  },
  Events: {
    verifySectionUrl: () => {
      const hash = window.location.hash;

      if (hash) {
        const target = document.querySelector(hash);

        if (target) {
          const offset = 100; // Distância que deseja remover
          const targetPosition =
            target.getBoundingClientRect().top + window.scrollY - offset;

          window.scrollTo({
            top: targetPosition,
            behavior: "smooth",
          });
        }
      }
    },
    archorLinks: (links) => {
      links.forEach((element) => {
        element.addEventListener("click", function (event) {
          event.preventDefault(); // Evita navegação padrão
          const href = element.getAttribute("href");
          const targetId = href.includes("#") ? `#${href.split("#")[1]}` : null;
          const target = targetId ? document.querySelector(targetId) : null;

          if (target) {
            const offset = 100; // Distância que deseja remover
            const targetPosition =
              target.getBoundingClientRect().top + window.scrollY - offset;

            window.scrollTo({
              top: targetPosition,
              behavior: "smooth",
            });
          } else {
            window.location.href = href;
          }
        });
      });
    },
    handleScroll: () => {
      const App = Global;
      if (window.scrollY > 50) {
        if (!App.header.classList.contains("active")) {
          App.header.classList.add("scroll");
        }
      } else {
        App.header.classList.remove("scroll");
      }
    },
    toggleMenu: () => {
      const App = Global;
      App.header.classList.toggle("active");

      if (App.header.dropdown) {
        App.header.dropdown.forEach((element) => {
          element.classList.remove("active");
        });
      }
    },
  },
  Forms: {
    ableSubmit: (form) => {
      const checkPrivacity = form.querySelector("input[name=aceite_privacidade]");
      form.querySelector("input[type=submit]").style.cursor = 'not-allowed';
      form.querySelector("input[type=submit]").disabled = true;

      form.querySelector("input[name=aceite_privacidade]").addEventListener("change", () => {
        if(checkPrivacity.checked == true){
          form.querySelector("input[type=submit]").style.cursor = 'pointer';
          form.querySelector("input[type=submit]").disabled = false;
        } else{
          form.querySelector("input[type=submit]").style.cursor = 'not-allowed';
          form.querySelector("input[type=submit]").disabled = true;
        }
      });

    },
    validFormHome: () => {
      const self = Global;
      const form = document.querySelector(".form-home form");
      self.Forms.ableSubmit(form);

      form.addEventListener("submit", (event) => {
        event.preventDefault();

        let inputName = form.querySelector('input[name="nome"]');
        let inputEmail = form.querySelector('input[name="email"]');
        let inputTel = form.querySelector('input[name="tel"]');
        let inputCompany = form.querySelector('select[name="instituicao"]');

        if(!self.Forms.validateFields(inputName.value, 'text')){
          inputName.focus();
          return;
        }

        if(!self.Forms.validateFields(inputEmail.value, 'email')){
          inputEmail.focus();
          return;
        }

        if(!self.Forms.validateFields(inputTel.value, 'tel')){
          inputTel.focus();
          return;
        }

        if(!self.Forms.validateFields(inputCompany.value, 'text')){
          inputCompany.focus();
          return;
        }
        form.submit();
      });
    },
    validFormWork: () => { 
      const self = Global;
      const form = document.querySelector(".form-work form");
      self.Forms.ableSubmit(form);

      form.addEventListener("submit", (event) => {
        event.preventDefault();

        let inputName = form.querySelector('input[name="nome"]');
        let inputEmail = form.querySelector('input[name="email"]');
        let inputTel = form.querySelector('input[name="tel"]');
        let inputCompany = form.querySelector('select[name="instituicao"]');

        if(!self.Forms.validateFields(inputName.value, 'text')){
          inputName.focus();
          return;
        }

        if(!self.Forms.validateFields(inputEmail.value, 'email')){
          inputEmail.focus();
          return;
        }

        if(!self.Forms.validateFields(inputTel.value, 'tel')){
          inputTel.focus();
          return;
        }

        if(!self.Forms.validateFields(inputCompany.value, 'text')){
          inputCompany.focus();
          return;
        }
        form.submit();
      });
    },
    validFormOne: () => { 
      const self = Global;
      const form = document.querySelector(".form-academic form");
      self.Forms.ableSubmit(form);
      
      form.addEventListener("submit", (event) => {
        event.preventDefault();

        let inputName = form.querySelector('input[name="nome"]');
        let inputEmail = form.querySelector('input[name="email"]');
        let inputTel = form.querySelector('input[name="tel"]');
        let inputCompany = form.querySelector('select[name="instituicao"]');

        if(!self.Forms.validateFields(inputName.value, 'text')){
          inputName.focus();
          return;
        }

        if(!self.Forms.validateFields(inputEmail.value, 'email')){
          inputEmail.focus();
          return;
        }

        if(!self.Forms.validateFields(inputTel.value, 'tel')){
          inputTel.focus();
          return;
        }

        if(!self.Forms.validateFields(inputCompany.value, 'text')){
          inputCompany.focus();
          return;
        }
        form.submit();
      });
    },
    validateFields: (value, type) => {
      if(type == 'text'){
        return value.trim().length > 0 ? 1 : 0;
      }

      if(type == 'tel'){
        return value.replace(/\D/g, '').length >= 12 ? 1 : 0;
      }

      if(type == 'email'){
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      
        return regexEmail.test(value) ? 1 : 0;
      }
    }
  },
  Helpers: {
    debounce: function (func, wait) {
      let timeout;
      return function (...args) {
        const later = () => {
          clearTimeout(timeout);
          func.apply(this, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    },
  },
};

export default Global;
