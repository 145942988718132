const Modal = {
  init: function () {
    this.cacheSelectors();
  },
  cacheSelectors: function () {
    this.popupVideo = document.getElementById("popup-video") || null;
    this.plays = document.querySelectorAll(".box-video") || null;
    this.popupWork = document.querySelectorAll(".openWork") || null;
    this.popupWorkModal = document.querySelector(".modal-work") || null;
    
    if(this.popupWorkModal) {
      this.popupWorkModal.addEventListener("click", (event) => {
        this.Events.closeModalWork(event);
      });
      this.popupWork.forEach((item) => {
        item.addEventListener("click", (event) => {
          event.preventDefault();
          this.Events.showModalWork()
        });
      });
    }
    
    if(this.popupVideo){
      this.closePopup = this.popupVideo.querySelector(".close-video") || null;

      this.popupVideo.addEventListener("click", (event) => {
        this.Events.closeVideo();
      });
      this.closePopup.addEventListener("click", () => {
        this.Events.closeVideo();
      });
      this.plays.forEach((item) => {
        item.addEventListener("click", () => this.Events.showVideo(item));
      });
    }
  },
  Events: {
    closeModalWork: (event) => {
      const App = Modal;
      if(!event.target.closest(".box")){
        App.popupWorkModal.classList.remove("active", "show");
      }
    },
    showModalWork: () => {
      const App = Modal;
      App.popupWorkModal.classList.add("show");
      setTimeout(() => { App.popupWorkModal.classList.add("active"); }, 100);
    },
    closeVideo: () => {
      const App = Modal;
      App.popupVideo.classList.remove("active", "show");
      App.popupVideo.querySelector(".video-iframe").innerHTML = "";
    },
    showVideo: (item) => {
      const App = Modal;
      const play = item.querySelector(".play");
      const urlVideo = play.getAttribute("data-src");

      let video = `<iframe src="" 
        frameborder="0" 
        allow="autoplay" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      App.popupVideo.querySelector(".video-iframe").innerHTML = video;
      App.popupVideo.querySelector(
        ".video-iframe iframe"
      ).src = `${urlVideo}?autoplay=1`;
      App.popupVideo.classList.add("show");
      setTimeout(() => {
        App.popupVideo.classList.add("active");
      }, 100);
    }
  },
};

export default Modal;